import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';
import JourneyCardSlider, {
  JourneyYearIndicator,
  NextButton,
  PrevButton,
} from './JourneyCardSlider';

const JourneyStorySection = ({ journeyStories }) => {
  const [elementIndex, setElementIndex] = useState(0);

  return (
    <div className="w-full overflow-hidden">
      <div className="w-full max-w-6xl py-16 m-auto sm:px-6 md:py-24">
        <BoldTitle className="leading-snug">Our Journey</BoldTitle>
        <div className="mt-8 md:mt-16">
          <div className="relative w-full h-full pl-6 sm:pl-0 sm:w-1/2">
            <div className="h-full">
              <JourneyCardSlider
                journeyStories={journeyStories}
                elementIndex={elementIndex}
              />
            </div>
          </div>
          <div className="relative w-40 m-auto mt-12 sm:w-60 sm:mx-0">
            <div className="h-full">
              <JourneyYearIndicator
                journeyStories={journeyStories}
                elementIndex={elementIndex}
              />
            </div>
            <div className="flex justify-between text-neutral-0">
              <PrevButton
                disabled={elementIndex === 0}
                onClick={() => setElementIndex((prev) => prev - 1)}
              />
              <NextButton
                onClick={() => setElementIndex((prev) => prev + 1)}
                disabled={elementIndex === journeyStories.length - 1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyStorySection;

JourneyStorySection.propTypes = {
  journeyStories: PropTypes.array.isRequired,
};
