import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const JourneyCard = ({ title, description, storyImage, isActive }) => {
  return (
    <div className="w-full h-full">
      <div
        className={`flex flex-col min-h-[450px] lg:min-h-full  gap-4 p-5 border-2 shadow-xl lg:flex-row items-start rounded-2xl transition-all duration-300  mr-6 sm:mr-8 ${
          isActive ? 'border-neutral md:border-primary' : 'border-neutral'
        }`}
      >
        <div className="m-auto ">
          <img
            className="w-56 h-56 lg:h-36 lg:w-36 "
            src={storyImage}
            alt={`Icon for ${title}`}
          />
        </div>
        <div className="lg:w-96">
          <p className="my-2 mt-1 font-bold md:text-xl">{title}</p>
          <p className="text-sm text-neutral-7">{description}</p>
        </div>
      </div>
    </div>
  );
};

JourneyCard.propTypes = {
  year: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  storyImage: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export const JourneyYearIndicator = ({ journeyStories, elementIndex }) => (
  <motion.div
    className="flex w-full"
    animate={{
      translateX: -elementIndex * 55 + '%',
    }}
  >
    {journeyStories.map((journey, idx) => (
      <motion.div
        key={journey.year}
        className="text-center"
        animate={{
          minWidth: elementIndex === idx ? '100%' : '55%',
        }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <motion.p
          className={`${
            elementIndex === idx
              ? 'font-semibold text-primary'
              : 'text-neutral-2'
          } transition-all duration-200 ease-in-out text-3xl md:text-5xl`}
          key={journey.year}
        >
          {journey.year}
        </motion.p>
      </motion.div>
    ))}
  </motion.div>
);

JourneyYearIndicator.propTypes = {
  journeyStories: PropTypes.array.isRequired,
  elementIndex: PropTypes.number.isRequired,
};

export const PrevButton = ({ onClick, disabled }) => (
  <button
    type="button"
    className={`absolute left-0 flex items-center justify-center w-8 h-8 transform -translate-y-1/2 border rounded-full sm:w-12 sm:h-12 top-1/2  ${
      disabled ? 'bg-neutral-2' : 'bg-neutral-9 hover:bg-neutral-8'
    }`}
    disabled={disabled}
    onClick={onClick}
  >
    <FontAwesomeIcon size="lg" icon={faAngleLeft} className=" text-neutral" />
  </button>
);

PrevButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export const NextButton = ({ onClick, disabled }) => (
  <button
    type="button"
    className={`absolute right-0 flex items-center justify-center w-8 h-8 transform -translate-y-1/2 border rounded-full sm:w-12 sm:h-12 top-1/2  ${
      disabled ? 'bg-neutral-2' : 'bg-neutral-9 hover:bg-neutral-8'
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    <FontAwesomeIcon size="lg" icon={faAngleRight} className=" text-neutral" />
  </button>
);

NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const JourneyCardSlider = ({ journeyStories, elementIndex }) => (
  <motion.div
    className="flex w-full h-full"
    animate={{
      translateX: -elementIndex * 100 + '%',
    }}
    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
  >
    {journeyStories.map((journey, idx) => (
      <motion.div key={journey.year} className="h-full min-w-full">
        <JourneyCard {...journey} isActive={elementIndex === idx} />
      </motion.div>
    ))}
  </motion.div>
);

JourneyCardSlider.propTypes = {
  journeyStories: PropTypes.array.isRequired,
  elementIndex: PropTypes.number.isRequired,
};

export default JourneyCardSlider;
