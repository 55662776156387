import React from 'react';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';

const TeamsSection = ({ teamsInformation }) => {
  return (
    <div className="w-full bg-primary-0">
      <div className="w-full max-w-6xl px-6 py-16 m-auto md:py-24">
        <BoldTitle className="leading-snug">Team</BoldTitle>
        <div className="flex flex-col justify-between w-full gap-6 mt-8 lg:flex-row md:mt-16">
          <div className="w-full max-w-sm text-center md:text-base md:text-start">
            <div className="flex items-center gap-4 m-auto mb-2 w-max md:m-0 ">
              <img
                className="w-10 h-10 md:w-16 md:h-16"
                src="/icons/user-group.svg"
                alt={`User Group Icon`}
              />
              <p className="text-3xl md:text-5xl">25+</p>
            </div>
            <p className="mt-2 text-sm md:text-base">
              Motivated and ethically driven people working relentlessly to make
              the best Islamic applications in the world.
            </p>
          </div>
          <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-3 lg:max-w-xl">
            {teamsInformation.map((data, i) => {
              return (
                <div
                  key={data.id}
                  className={`${i === 0 ? 'md:col-span-3' : ''} ${
                    i === 5 ? 'md:col-span-2' : ''
                  } w-full`}
                >
                  <div className="flex items-center justify-between w-full p-4 border rounded-xl bg-neutral border-primary-3">
                    <div className="flex items-center justify-start gap-2">
                      <div className="p-6px rounded-lg bg-primary-2 w-max">
                        <img
                          className="w-6 h-6"
                          src={data.icon}
                          alt={`Icon for ${data.title}`}
                        />
                      </div>
                      <p className="text-sm">{data.title}</p>
                    </div>
                    <p className="text-2xl font-semiBold">{data.members}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsSection;

TeamsSection.propTypes = {
  teamsInformation: PropTypes.array.isRequired,
};
